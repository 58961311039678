.input-focus-animation {
  animation: focusAnimation 1s;
}

@keyframes focusAnimation {
  0% {
    outline: 0;
    box-shadow: 0 0 0 transparent;
  }
  50% {
    outline: 0;
    box-shadow: 0 0 15px rgba(0, 120, 255, 0.8); /* Adjust color as needed */
  }
  100% {
    outline: 0;
    box-shadow: 0 0 0 transparent;
  }
}
