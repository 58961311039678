.fadIn_Gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 77%,
    rgba(254, 252, 240, 1) 100%
  );
}
.fade-out {
  -webkit-mask-image: linear-gradient(
    180deg,
    rgb(255 255 255 / 2%) 0%,
    rgb(0 0 0) 40%,
    rgb(15 15 15) 100%
  );
  mask-image: linear-gradient(
    180deg,
    rgb(255 255 255 / 2%) 0%,
    rgb(0 0 0) 40%,
    rgb(15 15 15) 100%
  );
  mask-size: 100% 100%;
  overflow-y: scroll; /* Ensures scrolling works */
}

/* Hide scrollbar for Webkit-based browsers */
.fade-out::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.fade-out {
  scrollbar-width: none;
}

/* Hide scrollbar for Webkit-based browsers */
.fade-out::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.fade-out {
  scrollbar-width: none;
}
